import { navigate } from 'gatsby';
import {useLayoutEffect} from 'react';

const AnalyticsREEMEAPage = () => {

    useLayoutEffect(() => {
        navigate('/analytics/ceemea')
    }, [])

    return null;

}

export default AnalyticsREEMEAPage;
